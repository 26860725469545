import React, { PropsWithChildren } from 'react';
import * as TooltipTemplate from '@radix-ui/react-tooltip';

type Props = {
  text: string;
};

function Tooltip(props: PropsWithChildren<Props>) {
  const { text, children } = props;
  return (
    <TooltipTemplate.Provider>
      <TooltipTemplate.Root>
        <TooltipTemplate.Trigger asChild>
          <button className='IconButton'>{children}</button>
        </TooltipTemplate.Trigger>
        <TooltipTemplate.Portal>
          <TooltipTemplate.Content className='TooltipContent' sideOffset={5}>
            {text}
            <TooltipTemplate.Arrow className='TooltipArrow' />
          </TooltipTemplate.Content>
        </TooltipTemplate.Portal>
      </TooltipTemplate.Root>
    </TooltipTemplate.Provider>
  );
}

export default Tooltip;
