import Button from '@components/Button';
import Checkbox from '@components/Form/Checkbox';
import Input from '@components/Form/Input';
import RadioButton from '@components/RadioButton';
// import Icon from '@images/daten.svg';
import Select from '@components/Form/Select';
import React, { useEffect, useMemo, useRef, useState } from 'react';
import Tooltip from '@components/Tooltip';
import { useStorage } from '../Provider/StorageProvider';
import { useToken } from '../Provider/TokenProvider';
import { endpoints, getUrl, headers } from '../hooks/api';
import { ItemTariff, ProductSetting } from '../types';
import InfoSvg from './info.svg';

type Props = {
  previousStep: () => void;
  nextStep: () => void;
  currentProduct?: ItemTariff | undefined;
  isSideselling: boolean;
  product_setting: ProductSetting;
};

const DataForm = React.forwardRef<HTMLDivElement, Props>((props, ref) => {
  const { storage, setStorage } = useStorage();
  const [orderType, setOrderType] = useState<'neueinzug' | 'tarifwechsel'>('neueinzug');
  const { token } = useToken();
  const [error, setError] = useState<any>();
  const [ortInputV, setOrtInputV] = useState<string>(storage?.customer?.city || 'Witten');
  const [plzInputV, setPlzInputV] = useState<string>(storage?.customer?.post_code || '58452');
  const [plzInputAV, setPlzInputAV] = useState<string>(
    storage?.billing_address?.post_code || '58452',
  );
  const validPlz = ['58452', '58453', '58454', '58455', '58456'];

  useEffect(() => {
    if (storage?.customer?.is_new_customer === false) {
      setOrderType('tarifwechsel');
    }
  }, [storage?.customer?.is_new_customer]);

  const validateEmail = (value: string) => {
    const reg =
      /[a-z0-9!#$%&'*+/=?^_`{|}~-]+(?:\.[a-z0-9!#$%&'*+/=?^_`{|}~-]+)*@(?:[a-z0-9](?:[a-z0-9-]*[a-z0-9])?\.)+[a-z0-9](?:[a-z0-9-]*[a-z0-9])?/;
    return reg.test(value);
    // return reg.test(value) ? undefined : 'E-Mail-Adresse ist nicht valide';
  };

  const updateUrl = token ? getUrl(token, endpoints.details) : '';

  const isSameAdress = useMemo(() => {
    return (
      storage?.customer?.address === storage?.billing_address?.address &&
      storage?.customer?.house_number === storage?.billing_address?.house_number
    );
  }, [storage]);
  const [abweichendeAdresse, setAbweichendeAdresse] = React.useState(
    !!storage?.billing_address?.address && !isSameAdress,
  );

  /* Automatically set adress to witten when turning off abweichendeAdresse, disabled for current ticket 
  
  useEffect(() => {
    if (!abweichendeAdresse) {
      setOrtInputV('Witten');
    }
  }, [abweichendeAdresse]);

  */

  const getItemData = (data: any) => {
    const currentProductId = props.currentProduct?.product_id;

    const response = [];
    response.push({
      product_id: currentProductId,
      consumption: props.currentProduct?.consumption,
      consumption_point: {
        ...(data.ablesedatum ? { starts_at: data.ablesedatum } : {}),
        meter_number: data.zaelernummer,
        meter_reading: data.zaelerstand,
      },
    });

    return response;
  };

  const handleSubmit = (event: any) => {
    setError(undefined);
    event.preventDefault();

    // load form data
    const data: Record<string, string> = {};
    for (let i = 0; i < event.target.elements.length; i += 1) {
      const element = event.target.elements[i];

      switch (element.type) {
        case 'button' || 'submit':
          break;
        case 'checkbox':
          data[element.name] = element.checked;
          break;
        default:
          data[element.name] = element.value;
          break;
      }
    }

    // check whether valid entries for plz and ort before submit
    if (
      (!props.isSideselling &&
        ((!abweichendeAdresse && data.ort !== 'Witten') ||
          (!validPlz.includes(data.postleitzahl) && !abweichendeAdresse) ||
          (!validPlz.includes(data.a_postleitzahl) && abweichendeAdresse))) ||
      (!props.isSideselling &&
        abweichendeAdresse &&
        !validPlz.includes(data.a_postleitzahl) &&
        data.a_ort !== 'Witten')
    ) {
      return '';
    }

    fetch(updateUrl, {
      method: 'PATCH',
      headers,
      body: JSON.stringify({
        checkout: {
          items: getItemData(data),
          ...(!props.isSideselling
            ? {
                email: data.email,
                customer: {
                  salutation: data.salutation,
                  title: data.title || '',
                  first_name: data.vorname,
                  last_name: data.nachname,
                  birthdate: data.geburtstag,
                  phone: data.telefonnummer,
                  address: data.strasse,
                  house_number: data.hausnummer,
                  post_code: data.postleitzahl,
                  city: data.ort,
                  country: 'Deutschland',
                  phone_advertising: data.telefonwerbung,
                  mail_advertising: data.emailwerbung,
                  contract_account_number: data.contract_account_number,
                  is_new_customer: orderType === 'neueinzug',
                },
              }
            : {}),
          /*
           Billingaddress is not actually the billingadress but the deliveryaddress.
           This was not corrected as it is already implemented into the db and to avoid further errors, emails and OrderOverview component will receive optional headlines instead.
          */
          billing_address: {
            address: data.a_strasse || data.strasse,
            house_number: data.a_hausnummer || data.hausnummer,
            post_code: data.a_postleitzahl || data.postleitzahl,
            city: data.a_ort || data.ort,
            country: 'Deutschland',
          },
        },
      }),
    }).then(padchedRes => {
      if (padchedRes.status >= 300) {
        padchedRes.json().then(newError => {
          setError(newError);
        });
        return;
      }

      if (storage) {
        padchedRes.json().then(newdata => {
          setStorage({ ...storage, ...newdata });
        });
      }
      props.nextStep();
    });
  };

  const inputCityRef = useRef<HTMLInputElement | null>(null);
  const inputPlzRef = useRef<HTMLInputElement | null>(null);
  const inputPlzARef = useRef<HTMLInputElement | null>(null);

  /* Reset custom validity when abweichendeAdresse is changed or the cityinput is changed */
  useEffect(() => {
    if (
      (inputCityRef.current && abweichendeAdresse) ||
      (!abweichendeAdresse && inputCityRef.current?.value === 'Witten')
    ) {
      inputCityRef.current?.setCustomValidity('');
    }
  }, [abweichendeAdresse, ortInputV]);

  /* Reset custom validity when abweichendeAdresse is changed or the plzainput is changed */
  useEffect(() => {
    if (
      inputPlzARef.current &&
      abweichendeAdresse &&
      (parseFloat(inputPlzARef.current?.value) >= 58452 ||
        parseFloat(inputPlzARef.current?.value) <= 58456)
    ) {
      inputPlzARef.current?.setCustomValidity('');
    }
  }, [abweichendeAdresse, plzInputAV]);

  /* Reset custom validity when abweichendeAdresse is changed or the plzinput is changed */
  useEffect(() => {
    if (
      inputPlzRef.current &&
      abweichendeAdresse &&
      (parseFloat(inputPlzRef.current?.value) >= 1000 ||
        parseFloat(inputPlzRef.current?.value) <= 99999)
    ) {
      inputPlzRef.current?.setCustomValidity('');
    } else if (
      inputPlzRef.current &&
      !abweichendeAdresse &&
      (parseFloat(inputPlzRef.current?.value) >= 58452 ||
        parseFloat(inputPlzRef.current?.value) <= 58456)
    ) {
      inputPlzRef.current?.setCustomValidity('');
    }
  }, [abweichendeAdresse, plzInputV]);

  return (
    <div className='dataForm' ref={ref}>
      <h2>Ihre Daten - Ihr {props.product_setting.name}vertrag</h2>

      <form onSubmit={async e => handleSubmit(e)}>
        <div
          onClick={() => setOrderType('neueinzug')}
          onKeyUp={() => setOrderType('neueinzug')}
          role='presentation'
          className='customer-type'
        >
          <RadioButton
            checked={orderType === 'neueinzug'}
            // items={[
            //   { label: 'Neueinzug', value: 'neueinzug' },
            //   { label: 'Bestandskunde', value: 'bestandskunde' },
            // ]}
          />
          <span>Neukunde</span>
        </div>
        <div
          onClick={() => setOrderType('tarifwechsel')}
          onKeyUp={() => setOrderType('tarifwechsel')}
          role='presentation'
        >
          <RadioButton checked={orderType === 'tarifwechsel'} />
          <span>Tarifwechsel</span>
        </div>
        {orderType !== 'neueinzug' ? (
          <Input
            label='Vertragskontonummer'
            id='contract_account_number'
            placeholder=''
            required
            type='number'
            defaultValue={storage?.customer?.contract_account_number || ''}
          />
        ) : null}
        {props.isSideselling ? null : (
          <>
            <div className='InputRow greets'>
              {/* <Input
                id='salutation'
                label='Anrede'
                placeholder=''
                required
                defaultValue={storage?.customer?.salutation || ''}
              /> */}
              <Select
                ariaLabel='anrede'
                label='Anrede'
                id='salutation'
                className='input'
                defaultValue={storage?.customer?.salutation || ''}
                items={[
                  {
                    value: 'Herr',
                    text: 'Herr',
                  },
                  {
                    value: 'Frau',
                    text: 'Frau',
                  },
                ]}
              />
              <Input
                label='Titel'
                placeholder='Titel'
                id='title'
                required={false}
                defaultValue={storage?.customer?.title || ''}
                errorText={error?.checkout?.customer?.title?.[0]}
              />
            </div>

            <div className='InputRow names'>
              <Input
                label='Vorname'
                placeholder=''
                id='vorname'
                required
                defaultValue={storage?.customer?.first_name || ''}
                errorText={error?.checkout?.customer?.first_name?.[0]}
                // validation={() => error?.checkout?.customer?.first_name?.[0]}
              />
              <Input
                label='Nachname'
                placeholder=''
                id='nachname'
                required
                defaultValue={storage?.customer?.last_name || ''}
                errorText={error?.checkout?.customer?.last_name?.[0]}
              />
            </div>

            <div className='InputRow'>
              <Input
                label='Geburtstag'
                placeholder=''
                id='geburtstag'
                required
                type='date'
                defaultValue={storage?.customer?.birthdate}
                errorText={error?.checkout?.customer?.birthdate?.[0]}
              />
              <Input
                label='Telefonnummer'
                placeholder=''
                id='telefonnummer'
                required
                defaultValue={storage?.customer?.phone || ''}
                errorText={error?.checkout?.customer?.phone?.[0]}
              />
            </div>

            <div className='InputRow'>
              <Input
                label='E-Mail'
                placeholder=''
                id='email'
                required
                type='email'
                defaultValue={storage?.email || ''}
                validation={validateEmail}
                errorText={error?.checkout?.email?.[0]}
              />
            </div>

            <h2 className='spacer'>Rechnungsadresse</h2>
            <div className='InputRow street'>
              <Input
                label='Straße'
                placeholder=''
                id='strasse'
                required
                defaultValue={storage?.customer?.address || ''}
                errorText={error?.checkout?.customer?.address?.[0]}
              />
              <Input
                label='Nr'
                placeholder=''
                id='hausnummer'
                required
                defaultValue={storage?.customer?.house_number || ''}
                errorText={error?.checkout?.customer?.house_number?.[0]}
              />
            </div>

            <div className='InputRow town'>
              <Input
                label='PLZ'
                placeholder=''
                id='postleitzahl'
                required
                type='number'
                ref={inputPlzRef}
                value={plzInputV}
                onChange={e => {
                  e.preventDefault();
                  setPlzInputV(e.target.value);
                }}
                onInvalid={() => {
                  if (!abweichendeAdresse) {
                    inputPlzRef.current?.setCustomValidity(
                      'Lieferadresse muss in Witten liegen (PLZ von 58452 bis 58456)',
                    );
                  } else if (abweichendeAdresse) {
                    inputPlzRef.current?.setCustomValidity('Keine gültige PLZ');
                  }
                }}
                max={!abweichendeAdresse ? 58456 : 99999}
                min={!abweichendeAdresse ? 58452 : 1000}
                errorText={error?.checkout?.customer?.post_code?.[0]}
                // validation={value => value.length == 5}
              />
              <Input
                label='Ort'
                placeholder=''
                id='ort'
                required
                value={ortInputV}
                ref={inputCityRef}
                pattern={!abweichendeAdresse ? 'Witten' : undefined}
                onChange={e => {
                  e.preventDefault();
                  setOrtInputV(e.target.value);
                }}
                onInvalid={() => {
                  inputCityRef.current?.setCustomValidity(
                    'Adresse (Lieferadresse) muss in Witten liegen',
                  );
                }}
                errorText={error?.checkout?.customer?.city?.[0]}
              />
            </div>
          </>
        )}
        <div className='delivery_address'>
          <Checkbox
            id='abweichende_adresse'
            label='Abweichende Lieferadresse?'
            value='abweichende_adresse'
            onCheckedChange={setAbweichendeAdresse}
            checked={abweichendeAdresse}
          />
          <Tooltip text='Hierunter ist das konkrete Gebäude zu verstehen, welches mit Strom oder Gas beliefert werden soll.'>
            <InfoSvg className='delivery-address__info-icon' />
          </Tooltip>
        </div>
        {abweichendeAdresse ? (
          <div className='alternate_address'>
            <h2 className='spacer'>Lieferadresse</h2>
            <div className='InputRow'>
              <Input
                label='Straße'
                placeholder=''
                id='a_strasse'
                required
                defaultValue={storage?.billing_address?.address || ''}
                errorText={error?.checkout?.billing_address?.address?.[0]}
              />
              <Input
                label='Hausnummer'
                placeholder=''
                id='a_hausnummer'
                required
                defaultValue={storage?.billing_address?.house_number || ''}
                errorText={error?.checkout?.billing_address?.house_number?.[0]}
              />
            </div>

            <div className='InputRow'>
              <Input
                label='Postleitzahl'
                placeholder=''
                id='a_postleitzahl'
                required
                type='number'
                max={58456}
                min={58452}
                ref={inputPlzARef}
                value={plzInputAV}
                onChange={e => {
                  e.preventDefault();
                  setPlzInputAV(e.target.value);
                }}
                onInvalid={() => {
                  inputPlzARef.current?.setCustomValidity(
                    'Lieferadresse muss in Witten liegen (PLZ von 58452 bis 58456)',
                  );
                }}
                errorText={error?.checkout?.billing_address?.post_code?.[0]}
              />
              <Input
                label='Ort'
                placeholder=''
                id='a_ort'
                required
                value='Witten'
                disabled
                errorText={error?.checkout?.billing_address?.city?.[0]}
              />
            </div>
          </div>
        ) : null}

        <h2 className='spacer'>Ihr Zählerstand</h2>
        <div className='InputRow'>
          <Input
            label='Zählernummer'
            placeholder=''
            id='zaelernummer'
            required
            type='text'
            defaultValue={props?.currentProduct?.consumption_point?.meter_number}
            errorText={error?.checkout?.items?.[0]?.consumption_point?.meter_number?.[0]}
          />
          <Input
            label='Zählerstand'
            placeholder=''
            id='zaelerstand'
            type='number'
            required
            // max={10000000}
            defaultValue={props?.currentProduct?.consumption_point?.meter_reading}
            errorText={error?.checkout?.items?.[0]?.consumption_point?.meter_reading?.[0]}
          />
        </div>

        <div className='InputRow'>
          <Input
            label='Ablesedatum'
            placeholder=''
            id='ablesedatum'
            defaultValue={
              props?.currentProduct?.consumption_point?.starts_at ??
              new Date().toISOString().split('T')[0]
            }
            type='date'
            required={false}
            errorText={error?.checkout?.items?.[0]?.consumption_point?.starts_at?.[0]}
          />
        </div>

        <div className='required'>
          <span>*</span> Pflichtfeld
        </div>
        <p className='navigation-row'>
          <Button className='prev' onClick={() => props.previousStep()}>
            Zurück
          </Button>
          <Button className='next' primary onClick={() => {}}>
            Weiter
          </Button>
        </p>
      </form>
    </div>
  );
});

DataForm.displayName = 'DataForm';

export default DataForm;
