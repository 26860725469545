import dynamic from 'next/dynamic';
import { StreamfieldLoaderFunction } from '@/types';
import Accordions from './Accordions';
import BonusweltSlider from './BonusweltSlider';
import Buttons from './Buttons';
import CTA from './CTA';
import Cards from './Cards';
import Columns from './Columns';
import ContactGroup from './ContactGroup';
import Container from './Container';
import Divider from './Divider';
import DonationBooth from './DonationBooth';
import FeatureTiles from './FeatureTiles';
import Image from './Image';
import Links from './Links';
import MagazinSlider from './MagazinSlider';
import Message from './Message';
import Order from './Order';
import ProductOverview from './ProductOverview';
import Quote from './Quote';
import Richtext from './Richtext';
import Slider from './Slider';
import SolarRechner from './SolarRechner';
import Stellen from './Stellen';
import Table from './Table';
import Tabs from './Tabs';
import Video from './Video';
import Prices from './Prices';
import RSSFeed, { loader as rssloader } from './RSSFeed';
import NewsletterRegister from './NewsletterRegister';
import Services from './Services';
import KwhCalculator from './KwhCalculator';
import AccordionsTable from './AccordionsTable';

const streamfields = {
  cta: CTA,
  buttons: Buttons,
  message: Message,
  full_richtext: Richtext,
  stellen: Stellen,
  simple_richtext: Richtext,
  table: Table,
  code: Richtext,
  container: Container,
  image: Image,
  links: Links,
  accordion: Accordions,
  video: Video,
  tabs: Tabs,
  cards: Cards,
  columns: Columns,
  divider: Divider,
  quote: Quote,
  feature_tiles: FeatureTiles,
  bonuswelt_slider: BonusweltSlider,
  order: Order,
  order_intro: KwhCalculator,
  solar_rechner: SolarRechner,
  contact_group: ContactGroup,
  magazin_slider: MagazinSlider,
  product_overview: ProductOverview,
  imageslider: Slider,
  donation_booth: DonationBooth,
  prices: Prices,
  rss_feed: RSSFeed,
  newsletterregister: NewsletterRegister,
  services: Services,
  accordion_table: AccordionsTable,
  map: dynamic(() => import('./Map'), { ssr: false }),
};

export type Streamfields = keyof typeof streamfields;

const loaders: Partial<Record<Streamfields, StreamfieldLoaderFunction<any, any>>> = {
  rss_feed: rssloader,
};

export { loaders };
export default streamfields;
